:root {
  --body-background: #282c35;
  --body-color: hsla(0,0%,100%,0.88);
  --pink: #ffa7c4;
}

@font-face {
  font-family: Merriweather;
  src: url(../fonts/Merriweather-Regular.ttf);
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: 'Merriweather', sans-serif;
  font-weight: 400;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2.625rem 1.3125rem;
}

a {
  color: var(--pink);
}

h1 {
  font-family: 'Merriweather', sans-serif;
  font-weight: 900;
  line-height: 2.625rem;
  font-size: 1.98818rem;
}

h3 {
  font-family: 'Merriweather', sans-serif;
  font-size: 1.75rem;
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: 0.4375rem;
}

p {
  margin-bottom: 1.75rem;
}

blockquote {
  font-style: italic
}

img {
  max-width: 590px;
  width: 100%; 
  height: 100%; 
  margin: 0px; 
  top: 0px; 
  left: 0px; 
  opacity: 1; 
  transition: opacity 0.5s ease 0s; 
  color: inherit; 
  box-shadow: white 0px 0px 0px 400px inset;
  position: relative; 
  display: block;
  margin-left: auto; 
  margin-right: auto;
}

main {
  max-width: 60rem;
}
